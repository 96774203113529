header {
  position: fixed;
  top: 0;
  width: 100%;
  // height: 65px;
  .navbar {
    background-color: #2d9cdb !important;
    position: relative;
    padding: 0px !important;

    height: 65px;
    .navbar-toggler {
      border: none;
      outline: none;
      z-index: 100;
    }
    .selected-option {
      color: #ffffff;
      font-size: 18px;
      font-weight: 400;
    }
    .navbar-collapse {
      left: auto;
      //  right: -300px;
      position: fixed;
      left: 0 !important;
      width: 290px;
      top: 0;
      height: 100vh !important;
      transform: translate(0);
      padding: 5rem 0rem 0rem;
      background-color: #2d9cdb !important;
      //  transform: translateX(${props => (props.collapsed ? '-290px' : '0')});
      //  transition: transform 0.3s ease;
      //  transition: 0.25s ease;
      h4 {
        color: #ffffff;
        font-weight: 500;
        line-height: 21px;
        font-size: 20px;
      }
      .navbar-nav {
        // direction: ltr;
        padding-top: 30px;
        color: #ffffff;
        width: 100% !important;
        .avatar-wrap {
          width: 40px;
          max-width: 40px;
          height: 40px;
          min-height: 40px;
          overflow: hidden;
          border-radius: 100%;
          display: inline-block;
          font-size: 11px;
          line-height: 15px;
        }
        .nav-item {
          padding-top: 0.6rem;
          padding-bottom: 0.6rem;
          font-size: 18px;
          line-height: 27px;
          font-weight: 400;
          &:hover {
            // background-color: #ffffff;
            // color: #20639B;
          }
          &.active {
            background-color: #ffffff;
            color: #2d9cdb !important;
          }
          width: 100% !important;
          // margin-top: .3rem;
        }
        .nav-item-icon {
          margin-left: 1rem;
          margin-right: 0;
          padding-left: 15px;
          padding-right: 10px;
        }
      }
      &.show {
        left: 0;
        right: auto;
        // width: 100% !important;
        width: inherit !important;
      }
    }
  }

  .aside-toggler {
    transition-behavior: normal;
    transition-duration: 0.25s;
    transition-timing-function: ease;
    transition-delay: 0s;
    &.active {
      transform: rotate(180deg) !important;
      transform-origin: 50% 50% !important;
      
    }
  }
 

  .collapse:not(.show) {
    width: 66px !important;
    display: inline-block !important;
  }
  .collapse {
    display: inline-block !important;
    width: 66px !important;
  }
  .collapsing {
    -webkit-transition: none !important;
    transition: none !important;
    display: none !important;
    &.collapse-horizontal {
      width: 66px !important;
      height: auto;
      // @include transition($transition-collapse-width);
    }
  }

}
.logo-header-img {
  max-width: 200px !important;
  -webkit-filter: invert(100%) sepia(0) saturate(7500%) hue-rotate(187deg) brightness(121%) contrast(114%) !important;
  filter: invert(100%) sepia(0) saturate(7500%) hue-rotate(187deg) brightness(121%) contrast(114%) !important;
  stroke: #fff !important;
  // height: 100px !important;
}
.logo-invoice-img{
  max-width: 200px !important;
  -webkit-filter: invert(100%) sepia(0) saturate(7500%) hue-rotate(187deg) brightness(121%) contrast(114%) !important;
  filter: invert(100%) sepia(0) saturate(7500%) hue-rotate(187deg) brightness(121%) contrast(114%) !important;
  stroke: #2D9CDB !important;
}