@import "./Login.scss";
@import "./components.scss";
@import "./lang_dir.scss";
@import "./header.scss";
@import "./companies.scss";
@import "./companyOverview.scss";

.loading-bar {
  height: 3px;
  background-color: rgb(255, 255, 255);
  position: fixed;
  top: 0px;
  z-index: 99999999;
}
.btn-scroll-top {
  background: rgba(#20639b, 0.5) !important;
  width: 60px;
  height: 60px;
  position: fixed;
  top: 4rem;
  right: 1rem;
  z-index: 1000;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;

  &.hide {
    display: none;
  }

  &.show {
    display: flex;
  }

  &:hover {
    background: #20639b;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &:active {
    box-shadow: none;
  }
}
body {
  background-color: #ffffff !important;
}
