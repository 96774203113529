.company-wrap {
  padding-top: 4.8rem !important;
  background-color: #f2f2f2 !important;
  // margin-left: 17rem !important;
  .side-company-info {
    padding-left: 1rem !important;
    background-color: #ffffff !important;
    border-radius: 5px;
    .action-wrap {
      width: 35px;
      height: 35px;
      background-color: #f9f9f9;
      border-radius: 5.53px;
      float: right;
      margin-right: 10px;
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .company-logo {
      padding-top: 47px;
    }
    .company-location {
      .name {
        color: #3f4254;
        font-weight: 700;
        font-size: 16px;
        line-height: 24.38px;
        padding-top: 27px;
      }
      .site {
        color: #4f4f4f;
        font-weight: 400;
        font-size: 12px;
        line-height: 19.5px;
        padding-bottom: 23px;
      }
    }
    .details-wrap {
      padding-bottom: 10px;
      .left-side {
        color: #333333;
        font-weight: 500;
        font-size: 14px;
        line-height: 19.5px;
      }
      .right-side {
        color: #4f4f4f;
        font-weight: 400;
        font-size: 14px;
        line-height: 19.5px;
      }
    }
    .line {
      width: 167px;
      // width: 242px;
      height: 1px;
      background-color: #e1e3ea;
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 10px;
    }
    .list-wrap {
      margin-top: 10px;
      div {
        height: 52px;
        border-radius: 5.46px;
        color: #4f4f4f !important;
        font-weight: 500;
        font-size: 14px;
        line-height: 19.5px;
        margin-right: 1.5rem !important;
        padding-left: 43px;
        display: flex;
        align-items: center;
        cursor: pointer;
        &.active {
          background-color: rgba(32, 99, 155, 0.1);
          color: #2d9cdb !important ;
          font-weight: 600;
          font-size: 14px;
          line-height: 19.5px;
        }
      }
    }
  }
  .company-overview {
    .main-overview-wrap {
      display: flex !important;
      margin-top: 10px;
      justify-content: space-between;
      margin-left: 30px;
      margin-right: 30px;
      align-items: center;
      .title-subscription {
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
      }
      .back-btn {
        min-width: 74px !important;
        // width:100%;
        height: 38px;
        border-radius: 5.46px;
        padding: 9px 20px 9px 20px !important;
        color: #4f4f4f !important;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        border: none !important;
        outline: none !important;
        background-color: #ffffff !important;
        // white-space: nowrap !important;
        // flex:none !important;
      }
      .send-btn {
        background-color: #2d9cdb !important;
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        padding: 9px, 20px, 9px, 20px;
        border-radius: 5.46px;
        width: 95px;
        height: 38px;
        border: none !important;
        outline: none !important;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.7rem;
        margin-left: 10px;
      }
    }
  }
}
.subscription-id {
  margin-left: 31px;
  color: #828282 !important;
  font-size: 12px;
  line-height: 18px;
}
.main-overview-wrap {
  display: flex !important;
  margin-top: 10px;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
  align-items: center;
  .title-subscription {
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
  }
  .back-btn {
    width: 74px;
    height: 38px;
    border-radius: 5.46px;
    padding: 9px, 20px, 9px, 20px;
    color: #4f4f4f !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    border: none !important;
    outline: none !important;
    background-color: #ffffff !important;
  }
  .send-btn {
    background-color: #20639b;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 9px, 20px, 9px, 20px;
    border-radius: 5.46px;
    width: 95px;
    height: 38px;
    border: none !important;
    outline: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.7rem;
    margin-left: 10px;
  }
}
.data-wrap {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  .usage-wrap,
  .storage-wrap {
    padding: 1rem !important;
    width: 100% !important;
    border-radius: 7.74px;
    background: #ffffff;
  }
  .storage-range {
    width: 76%;
    height: 7.62px;
    background: #56ccf2;
    border-radius: 5.88px;
  }
  .progress {
    display: flex;
    height: 7.62px;
    /* overflow: hidden; */
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 5.88px;
    .progress-bar {
      background-color: #f2994a;
    }
  }
  .data-storage {
    .progress {
      display: flex;
      height: 7.62px;
      /* overflow: hidden; */
      font-size: 0.75rem;
      background-color: #e9ecef;
      border-radius: 5.88px;
      .progress-bar {
        background-color: #56ccf2;
      }
    }
  }

  .usage-range {
    width: 76%;
    height: 7.62px;
    background: #f2994a;
    border-radius: 5.88px;
  }
  // .charts-wrap{
  //   display: flex !important;
  //   // gap: 10px;
  // }
}
.subscription-invoice-wrap {
  padding: 1rem !important;
  width: 100% !important;
  border-radius: 7.74px;
  background: #ffffff;
  color: #4f4f4f;
  font-weight: 400;
  font-size: 13px;
  .title {
    color: #4f4f4f;
    font-size: 13px;
    font-weight: 600 !important;
    line-height: 20px;
  }
  .title-bold {
    color: #4f4f4f;
    font-size: 13px;
    font-weight: 700 !important;
    line-height: 20px;
  }
}
.company-notifications {
  // background: #ffffff;
  .date-wrapper {
    background: #ffffff !important;
    height: 38px !important;
    color: #4f4f4f !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 12px;
    display: flex !important;
    align-items: center !important;
    gap: 1rem !important;
    justify-content: end !important;
    position: relative;
    width: 100% !important;
  }
  .notification-wrap {
    background: #ffffff;
    border-radius: 8.13px;
    padding: 1rem;
    .ticket {
      color: #333333;
      font-weight: 400;
      font-size: 16px;
      line-height: 22.42px;
    }
    .day {
      color: #bdbdbd;
      font-weight: 400;
      font-size: 16px;
      line-height: 20.96px;
    }
    .status {
      border-radius: 5.53px;
      text-align: center !important;
      font-size: 11px;
      font-weight: 400;
      line-height: 11.05px;

      .in-progress {
        background-color: #f2c94c4d;
        color: #f2994a;
        padding: 5px;
        display: flex;
        align-items: center;
        border-radius: 5.53px;
      }
      .complete {
        background-color: #6fcf9740;
        color: #219653;
        padding: 5px;
        display: flex;
        align-items: center;
        border-radius: 5.53px;
      }
    }
    .detail {
      color: #333333;
      font-size: 15px;
      font-weight: 400;
      line-height: 22.42px;
      .explanation {
        color: #4f4f4f;
      }
    }
  }
  .logs-wrap {
    background: #ffffff;
    padding: 1rem;
    max-height: 400px;
    overflow-y: auto;
    height: 100%;
    .title {
      color: #333333;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }
    .info {
      color: #bdbdbd;
      font-weight: 400;
      font-size: 12px;
      line-height: 14.43px;
    }
    .log-details {
      // display: flex !important;
      // justify-content: space-between !important;
      // align-items: start !important;
      display: grid !important;
      grid-template-columns: 1fr auto !important;
      gap: 1rem;
      place-items: start !important;
      .left-side {
        color: #333333;
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        white-space: nowrap;
      }
      .right-side {
        color: #4f4f4f;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        position: relative;
        height: 100% !important;
      }
      .verticle-line {
        width: 4px;
        height: 100%;
        background-color: #e0e0e0;
        position: absolute;
        border-radius: 3px;
        left: 7px;
        z-index: 99;
      }
    }
  }
  .custom-select-dropdwon {
    width: 100%;
    border-radius: 6.18px 6.18px 6px 4px;
    box-shadow: 0px 0px 30px 0px #00000033;
    color: #4f4f4f !important ;
    // font-size: 13px;
    line-height: 15px;
    // padding: 1rem;
    position: absolute !important;
    background-color: #fff !important;
    z-index: 200 !important;
    // top: 10rem !important;
    right: 0 !important;
  }
  .select-field-date {
    // border: 1px solid #e0e0e0 !important; /* Add border to the select field */
    // padding: 2px;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .selectedSubItemsDate {
      margin-bottom: 5px;
      margin-top: 5px;
      background-color: #2d9cdb26;
      border-radius: 6px;
      margin-right: 6px;
      padding: 4px 8px;
      font-size: 14px;
      font-weight: 500 !important;
      cursor: pointer !important;
    }
  }
}
.site-detail-wrap {
  background: #ffffff;
  padding: 1rem !important;
  display: grid;
  grid-template-columns: 1fr 2fr;
  color: #4f4f4f;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  border-radius: 5.46px !important;
  div {
    margin-top: 1rem;
  }
}
.user-detail-wrap {
  @extend .site-detail-wrap;
  padding: 0rem !important;
  .options-wrap {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr auto;
  }
}

.user-grid {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 3fr;
  gap: 1rem;
}
.payment-card-wrap {
  background: #ffffff;
  padding: 1rem !important;
  border-radius: 5.46px !important;
  .title {
    color: #333333 !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  .rows-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #4f4f4f !important;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
  }
  .invoice-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
  }
  .title-blue {
    font-size: 13px;
    color: #20639b;
    cursor: pointer;
  }
  .date {
    font-size: 13px;
    color: #828282;
  }
  .invoice-id {
    font-size: 13px;
    color: #333333;
  }
  .subscription-notification-title {
    color: #333333;
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
  }
}
.subscription-sub-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #4f4f4f;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 0.5rem;
}
.sites-search-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 133px;
  height: 38px;
  background-color: #f2f2f2;
  color: #bdbdbd;
  padding: 1rem;
  border-radius: 5.46px;
  gap: 0.3rem;

  .sites-searchBar {
    color: #bdbdbd !important;
    &::placeholder {
      color: #bdbdbd !important;
    }
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    border: none !important;
    outline: none !important;
    color: #bdbdbd !important;
    overflow-x: auto;
    background-color: transparent !important;
    width: 100% !important;
  }
}
.custom-user-checkbox {
  pointer-events: none !important;
  @extend .checkbox;
  &:checked {
    background-color: #bdbdbd !important;
    color: #ffffff !important;
    &::placeholder {
      color: #bdbdbd !important;
    }
  }
}
.rmdp-arrow {
  border: 1px solid #2d9cdb !important;
  border-width: 0 2px 2px 0 !important;
}
.rmdp-arrow-container:hover {
  background-color: #2d9cdb !important;
  i {
    border: 1px solid #ffffff !important;
    border-width: 0 2px 2px 0 !important;
  }
}
.rmdp-day span {
  color: #7e8299 !important;
  font-size: 13px !important;
  border: none !important;
}
.rmdp-day.rmdp-today span {
  background-color: #f3f6f9 !important;
  color: #2d9cdb !important;
  border-radius: 5px;
}
.rmdp-day.rmdp-today {
  background-color: #f3f6f9 !important;
}
.rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
  background-color: #2d9cdb !important;
  color: #ffffff !important;
  border-radius: 0px !important;
}
.rmdp-week-day {
  background-color: transparent !important;
  color: #3f4254 !important;
  border: none !important;
}
.rmdp-range {
  background-color: #f3f6f9 !important;
  color: #333333 !important;
  box-shadow: none !important;
}
.rmdp-range.start:not(.force) {
  border-top-left-radius: 5.46px !important;
  border-bottom-left-radius: 5.46px !important;
  background-color: #2d9cdb !important;
  color: #ffffff !important;
}
.rmdp-header {
  font-size: 13px !important;
  color: #3f4254 !important;
}
.rmdp-range.end:not(.force) {
  border-top-right-radius: 5.46px !important;
  border-bottom-right-radius: 5.46px !important;
  background-color: #2d9cdb !important;
  color: #ffffff !important;
}
.rmpd-range {
  color: #3f4254 !important;
}
.rmdp-container {
  visibility: hidden !important;
}
.rmdp-range.start,
.rmdp-range.end {
  span {
    color: #ffffff !important;
    text-align: center !important;
  }
}
.rmdp-week-day {
  text-align: center !important;
}
.rmdp-day {
  span {
    text-align: center !important;
  }
  color: #7e8299 !important;
  cursor: pointer;
  /* height: 34px; */
  position: relative;
  /* width: 34px; */
  width: 33px !important;
  height: 33px !important;
  border-radius: 0px !important;
  &:hover {
    background-color: #2d9cdb !important;
    color: #ffffff;
    border-radius: 0px !important;
  }
}

.rmdp-arrow-container {
  display: flex !important;
}

.date-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center !important;
  gap: 20px;
  margin-bottom: 20px !important;
  margin-right: 20px;
  margin-left: 10px !important;
  .apply-date-button {
    //   color: #ffffff;
    color: #2d9cdb !important;
    background-color: transparent !important;
    //   width: 70px;
    //   height: 36px;
    font-size: 13px !important;
    font-weight: 700 !important;
    border-radius: 5.56px;
    border: none !important;
    margin-bottom: 20px !important;
    text-align: center !important;
  }
  .cancel-date-button {
    color: #828282 !important;
    background-color: transparent !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    //   background-color: #f2f2f2;
    //   width: 70px;
    //   height: 36px;
    border-radius: 5.56px;
    border: none !important;
    margin-bottom: 20px !important;
    text-align: center !important;
  }
}

.table-row {
  font-weight: 500 !important;
  cursor: pointer;
  &.deleted {
    color: #eb5757 !important;
  }
  td {
    padding: 23px !important;
  }
}
.table-header {
  th {
    border: none !important;
  }
}
// .chart-wrap {
//   .apexcharts-text tspan {
//     // font-size: 0.7vmax !important;
//   }
// }
.user-table {
  .table-row {
    font-weight: 400 !important;
    cursor: pointer;
    &.deleted {
      font-weight: 500;
      color: #eb5757 !important;
    }
    td {
      padding: 23px !important;
      font-size: 14px !important;
    }
  }
  .table-head {
    th {
      border: none !important;
      font-size: 14px !important;
      white-space: nowrap;
      cursor: pointer;
      color: #4f4f4f !important;
      font-weight: 700 !important;
    }
  }
}
.user-chart-wrap {
  .apexcharts-text tspan {
    font-size: 0.7vmax;
  }
}
.edit-btn {
  min-width: 62px;
  width: 100%;
  height: 38px;
  border-radius: 5.46px;
  padding: 9px, 20px, 9px, 20px;
  color: #ffffff !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border: none !important;
  outline: none !important;
  background-color: #2d9cdb !important;
  white-space: nowrap !important;
}
.info-detail-wrap-main {
  min-height: 700px !important;
  height: 100% !important;
  background: #ffffff;
  padding: 1rem !important;
  color: #4f4f4f !important;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  border-radius: 5.46px !important;
  .info-detail-wrap {
    @extend .site-detail-wrap;
    grid-template-columns: 1fr 3fr !important;
    height: fit-content !important;
    align-items: center;
    // div {
    //   // margin-top: 2rem !important;
    //   height: fit-content !important;
    // }
    .option-label {
      width: 100%; /* Set width for options */
      padding: 0px;
      font-size: 14px;
      font-weight: 500;
      // padding: 0px !important;
    }
    .info-input {
      width: 333px !important;
      background-color: #f2f2f2 !important;
      color: #4f4f4f !important;
      border: none !important;
      outline: none !important;
      height: 45px !important;
      font-size: 13px;
      font-weight: 400;
      line-height: 19.5;
      border-radius: 5.46px;
      padding-left: 1rem;
    }
    .readonly-input {
      @extend .info-input;
      background-color: #ffffff !important;
    }
  }
}
.my-select-field {
  border-radius: 5.46px !important;
  cursor: pointer;
  display: flex !important;
  flex-wrap: wrap !important;
  align-items: center !important;
  gap: 5px !important;
  justify-content: start !important;
  margin-top: 5px !important;
  z-index: 100;
  padding-left: 5px !important;
  padding-top: 5px !important;
  text-align: center !important;
  ::placeholder {
    color: #828282 !important;
  }
  background-color: #ffffff !important;
  border: 1px solid #e0e0e0 !important;
  width: 333px !important;
  min-height: 45px !important;
}
.select-field-readonly {
  @extend .my-select-field;
  border: none !important;
  cursor: context-menu !important;
}
.custom-label {
  label:hover {
    color: #2d9cdb !important;
    background-color: #f2f2f24d !important;
  }
}

.custom-table-responsive {
  min-height: 100% !important;
}
.custom-table-responsive-specific {
  min-height: 300px !important;
  height: 100% !important;
}
.empty-text {
  color: #4f4f4f !important;
  font-size: 17px !important;
}
.table-main-customized {
  width: 100% !important;
  color: #828282 !important;
  height: max-content !important;
  tbody {
    border-left: none !important;
    tr {
      white-space: nowrap;
      &:hover {
        background-color: #f2f2f280 !important;
      }
      td {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
      }
    }
  }
  .header {
    height: 100px !important;
  }
}
table {
  border-collapse: collapse !important;
  border-left: none !important;
}

.store-wrap {
  background: #ffffff;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  border-radius: 5.46px !important;
  min-height: 700px !important;
  height: 100%;
  display: grid !important;
  grid-row-gap: 0.5rem;
  grid-template-rows: 9rem repeat(9, 2rem) !important;
  grid-template-columns: 1fr 3fr !important;
  color: #4f4f4f !important;
  font-size: 12px;
  font-weight: 400;
  align-items: center !important;
}
.pos-wrap {
  display: grid !important;
  grid-template-columns: 1fr 3fr !important;

  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #4f4f4f !important;
  &.small {
    font-size: 13px !important;
    font-weight: 400 !important;
    grid-template-columns: 1fr 2fr !important;
  }
}
.pos-title {
  &.small {
    font-size: 14px !important;
  }
  color: #333333 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}
.roles-main-wrap {
  .title-main {
    color: #333333 !important;
    font-size: 13px;
    font-weight: 400 !important;
  }
}
.role-details-wrap {
  display: grid !important;
  grid-template-columns: 2fr 4fr !important;
  align-items: center;
  color: #333333 !important;

  font-size: 14px !important;
  .title {
    font-size: 13px !important;
    color: #333333 !important;
  }
}

.subs-details {
  .title {
    color: #333333 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  color: #4f4f4f !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}
